<!-- HEADER -->
<app-header></app-header>

<div class="content">
  <!-- Left side page menu -->
  <nav class="main-menu">
    <app-main-menu></app-main-menu>
  </nav>
  <!-- main order pages area -->
  <main class="main-section">
    <router-outlet></router-outlet>
  </main>
</div>
