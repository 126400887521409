import { Component } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { nav } from '@bd/shared/utils';
import { Observable, of } from 'rxjs';

export interface MenuItem {
  id?: string;
  icon: string;
  tooltip: string;
  link?: (() => string) | string;
  showBadge?: boolean;
  counter?: Observable<number>;
  onClick?: () => void;
  isActiveMatchOptions?: IsActiveMatchOptions;
}

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  private menuItems: MenuItem[] = [
    {
      link: nav.routes.workcenter,
      tooltip: 'MAIN_MENU.EDIT_WORK_CENTER',
      icon: 'icon-machine',
      isActiveMatchOptions: { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => this.router.navigate([nav.routes.workcenter])
    },
    {
      link: nav.routes.downtimeReason,
      tooltip: 'MAIN_MENU.MANAGE_DOWNTME_REASON',
      icon: 'icon-open-tasks',
      isActiveMatchOptions: { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' },
      onClick: () => this.router.navigate([nav.routes.downtimeReason])
    },
    {
      tooltip: 'MAIN_MENU.RESET_DB',
      icon: 'icon-power-off',
      isActiveMatchOptions: { paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored' }
    }
  ];

  public isLoading = false;
  public menuList$ = of(this.menuItems);

  constructor(private router: Router) {}

  public isMenuItemActive(menuItem: MenuItem): boolean {
    if (!menuItem.link) {
      return false;
    }

    const link = typeof menuItem.link === 'function' ? menuItem.link() : menuItem.link;

    return this.router.isActive(link, menuItem.isActiveMatchOptions);
  }
}
