import { NgModule } from '@angular/core';
import { WorkCenterComponent } from './containers/work-center.component';
import { SharedModule } from '@bd/shared/shared.module';
import { EditWorkcenterDetailsComponent } from './containers/components/edit-workcenter-details/edit-workcenter-details.component';

@NgModule({
  declarations: [WorkCenterComponent, EditWorkcenterDetailsComponent],
  imports: [SharedModule]
})
export class WorkCenterModule {}
