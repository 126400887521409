import { Component, OnDestroy, OnInit } from '@angular/core';
import { version } from '@version';
import { AppSettingsQuery } from 'chronos-shared';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public disabledWorkCenter: boolean;

  constructor(public appSettingsQuery: AppSettingsQuery) {}

  public version = version;
  public isProductionEnvironment$: Observable<boolean>;

  public ngOnInit(): void {
    this.isProductionEnvironment$ = this.appSettingsQuery.isProductionEnvironment$;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
