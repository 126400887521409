import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppInitService } from '@bd/app-init.service';
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  public asideEnabled = true;
  public showSpinner = false;
  private subscriptions = new Subscription();
  public productionOrderId = null;
  constructor(private appInitService: AppInitService, private renderer: Renderer2) {}

  public ngOnInit(): void {
    this.appInitService.loadingIndicatorSubject$.subscribe((val) => {
      const element = document.getElementById('loader');
      if (val) {
        this.renderer.setStyle(element, 'visibility', 'visible');
      } else {
        this.renderer.setStyle(element, 'display', 'none');
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
