import { Table } from '../models/table';

class BaseColumn implements Table {
  field: string;
  header: string;
  resizable: boolean;
  sortable: boolean;
  filterable: boolean;
  width: string;

  constructor(field: string, header: string, options?: Partial<Table>) {
    this.field = field;
    this.header = header;
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class FixedColumn extends BaseColumn {
  constructor(field: string, header: string, options?: Partial<Table>) {
    super(field, header, options);
    this.sortable = true;
    this.resizable = true;
    this.filterable = true;
  }
}

export class CustomColumn extends BaseColumn {
  constructor(field: string, header: string, options?: Partial<Table>) {
    super(field, header, options);
  }
}

export class ButtonColumn extends BaseColumn {
  constructor(field: string, header: string, options?: Partial<Table>) {
    super(field, header, options);
    this.sortable = false;
    this.resizable = false;
    this.filterable = false;
  }
}
