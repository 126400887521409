<section class="work-center">
  <div class="page-padding">
    <div class="work-center__container header">
      <h1 class="header__title">{{ 'BASE_DATA.WORK_CENTER_TITLE' | translate }}</h1>
    </div>
    <p-table
      #dt2
      [value]="products"
      dataKey="id"
      [resizableColumns]="true"
      [reorderableColumns]="true"
      columnResizeMode="expand"
      editMode="row"
      [styleClass]="'work-center-table'"
      [rows]="4"
      [paginator]="true"
    >
      <ng-template pTemplate="header">
        <tr class="work-center-table__header-row">
          <th
            *ngFor="let col of columns"
            [style.width]="col.width"
            [pSortableColumn]="col.field"
            [pSortableColumnDisabled]="!col.sortable"
            [pResizableColumnDisabled]="!col.resizable"
            [class]="col.styleClass"
            pResizableColumn
          >
            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
            {{ col.header }}
          </th>
        </tr>
        <tr>
          <th *ngFor="let col of columns">
            <p-columnFilter *ngIf="col.filterable" [field]="col.field" type="text" [placeholder]="col.searchablePlaceholder" />
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
        <tr [pEditableRow]="product" class="work-center-table__order-row">
          <td class="tiny-cell">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="product.code" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ product.code }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="medium-cell">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="product.name" required />
              </ng-template>
              <ng-template pTemplate="output">
                {{ product.name }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="medium-cell">
            <!-- <p-cellEditor>
                      <ng-template pTemplate="input">
                          <p-dropdown
                              [options]="statuses"
                              appendTo="body"
                              [(ngModel)]="product.status"
                              [style]="{'width':'100%'}" />
                      </ng-template>
                      <ng-template pTemplate="output">
                          <p-tag
                              [value]="product.inventoryStatus"
                              [severity]="getSeverity(product.inventoryStatus)" />
                      </ng-template>
                  </p-cellEditor> -->
          </td>
          <td class="number-cell">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="product.price" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ product.price }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="action-cell">
            <div class="flex align-items-center justify-content-center gap-2">
              <button
                *ngIf="!editing"
                pButton
                pRipple
                type="button"
                label="Edit"
                pInitEditableRow
                (click)="onRowEditInit(product)"
                class="p-button-rounded p-button-text"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                label="Save"
                pSaveEditableRow
                (click)="onRowEditSave(product)"
                class="p-button-rounded p-button-text p-button-success mr-2"
              ></button>
              <button
                *ngIf="editing"
                pButton
                pRipple
                type="button"
                label="Cancel"
                pCancelEditableRow
                (click)="onRowEditCancel(product, ri)"
                class="p-button-rounded p-button-text p-button-danger"
              ></button>
            </div>
            <!-- <div class="flex align-items-center justify-content-center gap-2">
              <lib-button
                *ngIf="!editing"
                pRipple
                pButton
                pInitEditableRow
                [typeClass]="'button--primary'"
                [sizeClass]="'button--small'"
                [label]="'Edit'"
                (clicked)="onRowEditInit(product)"
              ></lib-button>
                <lib-button
                *ngIf="editing"
                pRipple
                pButton
                pSaveEditableRow
                [typeClass]="'button--primary'"
                [sizeClass]="'button--small'"
                [label]="'Save' | translate"
                (clicked)="onRowEditSave(product)"
              ></lib-button>
              <lib-button
                *ngIf="editing"
                pRipple
                pButton
                pCancelEditableRow
                [typeClass]="'button--secondary'"
                [sizeClass]="'button--small'"
                [label]="'Cancel' | translate"
                (clicked)="onRowEditCancel(product, ri)"
              ></lib-button>
            </div> -->
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</section>
