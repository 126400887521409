import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { FrontendConfigService, FrontendFeatures } from 'chronos-core-client';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppSettingsService } from 'chronos-shared';

@Injectable({
  providedIn: 'root'
})
export class InitService {
  constructor(
    private appSettingsService: AppSettingsService,
    private titleService: Title,
    private translateService: TranslateService,
    private frontendConfigService: FrontendConfigService
  ) {}

  public initializeWorkCenter(): Promise<any> {
    this.setAppTitle();
    return this.initData()
      .pipe(
        tap(([features]: [FrontendFeatures]) => {
          this.appSettingsService.setAppFeatures(features);
        }),
        catchError((error) => this.onCatchError(error))
      )
      .toPromise();
  }

  private onCatchError(error: any): Observable<any> {
    return of(error);
  }

  private setAppTitle(): void {
    const title = this.translateService.instant('APP.TITLE');
    this.titleService.setTitle(`${title}`);
  }

  private initData(): Observable<[FrontendFeatures]> {
    return forkJoin([this.frontendConfigService.getFeatures()]);
  }
}
