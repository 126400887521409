import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResultSelectButtonComponent, SearchBarComponent, SplitButtonComponent } from '@bd/shared/components';
import { ElapsedTimePipe } from '@bd/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { ChronosInputDirective, ChronosSharedModule, ChronosTextareaDirective } from 'chronos-shared';
import { ListItemDirective } from '@bd/shared/directives/list-item/list-item.directive';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogConfig, DynamicDialogModule } from 'primeng/dynamicdialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CarouselModule } from 'primeng/carousel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { SelectButtonModule } from 'primeng/selectbutton';
import { BlockUIModule } from 'primeng/blockui';
import { TabMenuModule } from 'primeng/tabmenu';
import { InputNumberModule } from 'primeng/inputnumber';
import { TreeTableModule } from 'primeng/treetable';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';

@NgModule({
  declarations: [SplitButtonComponent, SearchBarComponent, ResultSelectButtonComponent, ElapsedTimePipe, ListItemDirective],
  imports: [
    // Angular Modules
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    // PrimeNg Modules
    TabViewModule,
    ButtonModule,
    DynamicDialogModule,
    InputTextModule,
    DropdownModule,
    KeyFilterModule,
    SplitButtonModule,
    DialogModule,
    SelectButtonModule,
    CheckboxModule,
    TableModule,
    InputTextareaModule,
    InputNumberModule,
    TreeTableModule,
    DragDropModule,
    TabMenuModule,
    CardModule,
    TooltipModule,
    RadioButtonModule,
    AutoCompleteModule,
    BlockUIModule,
    ProgressSpinnerModule,
    CarouselModule,

    // 3rd party Modules
    ChronosSharedModule,
    TranslateModule.forChild()
  ],
  exports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    // PrimeNG
    TabViewModule,
    ButtonModule,
    DynamicDialogModule,
    InputTextModule,
    DropdownModule,
    KeyFilterModule,
    SplitButtonModule,
    DialogModule,
    SelectButtonModule,
    CheckboxModule,
    TableModule,
    InputTextareaModule,
    InputNumberModule,
    TreeTableModule,
    DragDropModule,
    TabMenuModule,
    CardModule,
    TooltipModule,
    RadioButtonModule,
    AutoCompleteModule,
    ListboxModule,
    CarouselModule,

    // 3rd party Modules
    TranslateModule,
    ChronosSharedModule,

    // Shared Components
    SplitButtonComponent,
    SearchBarComponent,
    ResultSelectButtonComponent,

    // Shared Pipes
    ElapsedTimePipe,

    // Shared Directives
    ChronosInputDirective,
    ChronosTextareaDirective,
    ListItemDirective
  ],
  providers: [DialogService, DynamicDialogConfig]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
