import { Component, OnDestroy, OnInit } from '@angular/core';
import { Table } from '@bd/shared/models';
import { ButtonColumn, CustomColumn, FixedColumn } from '@bd/shared/utils/table';

interface Product {
  code: string;
  name: string;
  status: boolean;
  price: number;
}

@Component({
  selector: 'app-work-center',
  templateUrl: './work-center.component.html',
  styleUrls: ['./work-center.component.scss']
})
export class WorkCenterComponent implements OnInit {
  public products: Product[];
  clonedProducts: { [s: string]: Product } = {};

  constructor() {}

  columns: Table[] = [
    new FixedColumn('code', 'Code', { searchablePlaceholder: 'Search by code', styleClass: 'tiny-cell' }),
    new CustomColumn('name', 'Name', { sortable: false, styleClass: 'medium-cell' }),
    new CustomColumn('status', 'Inventory Status', { styleClass: 'medium-cell' }),
    new CustomColumn('price', 'Price', { sortable: true, styleClass: 'number-cell' }),
    new ButtonColumn('actions', 'Edit/Update', { styleClass: 'action-cell' })
  ];

  public ngOnInit(): void {
    this.products = this.getProducts();
  }

  onRowEditInit(product: Product) {
    this.clonedProducts[product.code as string] = { ...product };
  }

  onRowEditSave(product: Product) {
    if (product.price > 0) {
      delete this.clonedProducts[product.code as string];
    }
  }

  onRowEditCancel(product: Product, index: number) {
    this.products[index] = this.clonedProducts[product.code as string];
    delete this.clonedProducts[product.code as string];
  }

  private getProducts(): Product[] {
    return [
      { code: 'abcd0', name: 'Bamboo watch', status: true, price: 100 },
      { code: 'abcd1', name: 'Wood watch', status: true, price: 200 },
      { code: 'abcd2', name: 'Blue Band', status: true, price: 300 },
      { code: 'abcd3', name: 'T-shirt', status: true, price: 400 },
      { code: 'abcd4', name: 'Bracelet', status: true, price: 500 }
    ];
  }
}
