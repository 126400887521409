import { NgModule, Optional, SkipSelf } from '@angular/core';
import { SharedModule } from '@bd/shared/shared.module';
import { HeaderComponent, MainMenuComponent } from '@bd/shell/components';
import { ShellRoutingModule } from '@bd/shell/shell-routing.module';
import {
  ApiAuthorizationModule,
  ApplicationHeaderModule,
  MainMenuNavigationModule,
  ModalsModule,
  PhaseNavigationModule
} from 'chronos-shared';
import { ShellComponent } from './containers/shell/shell.component';
import { WorkCenterModule } from '@bd/modules/work-center/work-center.module';
import { DowntimeReasonModule } from '@bd/modules/downtime-reason/downtime-reason.module';

@NgModule({
  declarations: [ShellComponent, MainMenuComponent, HeaderComponent],
  imports: [
    SharedModule,
    ApiAuthorizationModule,

    // Basedata modules
    WorkCenterModule,
    DowntimeReasonModule,

    // Routing import
    ShellRoutingModule,

    // Chronos imports
    MainMenuNavigationModule,
    ApplicationHeaderModule,
    PhaseNavigationModule,
    ModalsModule
  ]
})
export class ShellModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: ShellModule
  ) {
    if (parentModule) {
      throw new Error('ShellModule is already loaded. Import it in the AppModule only.');
    }
  }
}
